@import 'src/styles/global_variables';
@import 'src/styles/mixins';

.back_button {
  padding: 8px 16px;
  background: #fff;
  border: 0;
  display: flex;
  align-items: flex-start;
  margin-right: auto;
  border-radius: 12px;
}

.main {
  @include element-size(auto, 100%);
  background-color: $grey-color-1;
  max-height: 100vh;
  max-width: 100vw;
  position: fixed;
  overflow: auto;
  z-index: 10;
  .main_content {
    position: relative;
  }
  .selected_apartment__container {
    @include element-size(auto, 100%);
    @include generalPadding;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .main_info__card {
      @include element-size(auto, 100%);
      @include generalPadding;
      padding: 12px 16px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: $light-color-1;
      border-radius: 12px;

      .img {
        width: 100%;
        height: 300px;
        object-fit: contain
      }

      .address__container {
        @include element-size(auto, 100%);
        display: flex;
        flex-direction: column;
        gap: 5px;

        .address__label {
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.4px;
          color: $grey-color-5;
        }

        .address_body {
          font-weight: 590;
          line-height: 22px;
        }

        .metro__info {
          @include flex-center;
          justify-content: start;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: -0.23px;
          color: $orange-color-1;
          font-size: 15px;
        }
      }
    }

    .banners__container {
      @include element-size(auto, 100%);
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .similar_apartment__container {
    @include element-size(auto, 100%);
    @include generalPadding;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: $light-color-1;
    border-radius: 16px;
  }

  .make_order_button__container {
    @include element-size(auto, 100%);
    @include generalPadding;
    padding-bottom: 32px;
    background-color: $light-color-1;
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    z-index: 100;

    .make_order_message__container {
      @include element-size(100%, 100%);
      @include flex-center;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $light-color-1;
      transition: $general-transition-time;
      opacity: 0;
      pointer-events: none;
    }

    .make_order_message__container.active {
      opacity: 1;
    }
  }
}
