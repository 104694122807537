@import 'src/styles/global_variables';
@import 'src/styles/mixins';

.main {
  @include element-size(auto, 100%);

  .filters__container {
    @include element-size(0, 100%);
    @include generalPaddingLeftRight;
    opacity: 0;
    pointer-events: none;
    transition: $general-transition-time;
    background-color: $grey-color-1;
    border-radius: 12px;

    .filter_item__container {
      @include element-size(auto, 100%);
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }

  .filter_item__button {
    font-weight: 600;
  }

  .filters__container.open {
    @include element-size(480px, 100%);
    @include generalPadding;
    opacity: 1;
    pointer-events: auto;
    margin-bottom: 10px;
  }

}
