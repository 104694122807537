@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$text-height: 24px;

.main {
  height: 96px;
  min-width: 82px;
  max-width: 82px;

  .image__container {
    @include element-size(calc(100% - #{$text-height}), 100%);
    @include flex-center;

    .selected_border__container {
      @include element-size(65px, 65px);
      @include flex-center;
      border-radius: 12px;
      border: 2px transparent solid;
      transition: $general-transition-time;

      .img {
        @include element-size(60px, 60px);
        border-radius: 8px;
      }
    }

    .selected_border__container.active {
      border: 2px $red-color-1 solid;
    }
  }

  .benefit_text {
    @include element-size($text-height, 100%);
    text-align: center;

    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

}
