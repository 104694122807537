@import '../../../styles/global_variables';
@import '../../../styles/mixins';

.main {
  @include element-size(100vh, 100vw);
  @include flex-center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: HexToRGB($light-color-1, .5);
  backdrop-filter: blur(3px);
  transition: $general-transition-time;

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid $red-color-1;
    border-bottom-color: $pink-color-1;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.main.hide {
  opacity: 0;
  pointer-events: none;
}