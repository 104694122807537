@import 'src/styles/global_variables';
@import 'src/styles/mixins';

.main {
  @include element-size(auto, 100%);
  @include generalPadding;
  background-color: $light-color-1;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4px;
  }

  .body {
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.23px;
    font-size: 15px;
    color: $grey-color-5;
  }
}