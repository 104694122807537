@import 'src/styles/global_variables';
@import 'src/styles/mixins';
@import 'src/components/project-carousel/project-carousel.module';

.main {
  @include element-size(100%, 100%);
  @include flex-center;
  position: relative;
  border-radius: 12px;
  .img {
    @include element-size(100%, 100%);
    object-fit: cover;
    object-position: center;
    border-radius: 12px;
  }

  .text__container {
    @include element-size(100%, 100%);
    padding: 20px;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 20%;
    padding-bottom: calc(#{$general-padding * 2 + $progress-line-height});
    box-sizing: border-box;

    .project_title {
      @include element-size(auto, 100%);

      color: $light-color-1;
      font-weight: 590;
      line-height: 22px;
      letter-spacing: -0.4px;
    }

    .description__container {
      @include element-size(auto, 100%);
      margin-top: auto;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .description_title {
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-family: "SF Pro";
        color: $light-color-1;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.4px;
        font-size: 26px;
      }

      .description_body {

        color: $light-color-1;
        font-weight: 400;
        line-height: 20px;
        font-size: 15px;
      }
    }
  }
}
