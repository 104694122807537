@import 'src/styles/global_variables';
@import 'src/styles/mixins';

.main {
  @include element-size(auto, 100%);
  @include flex-center;
  background-color: $red-color-1;
  color: $light-color-1;
  padding: 15px 12px 15px 12px;
  font-weight: 700;
  font-size: 17px;
  line-height: 17px;
  font-family: Roboto, sans-serif;
  border-radius: 12px;
  border: 1px solid $red-color-1;
  box-sizing: border-box;
  cursor: pointer;
}

.main.white_mode {
  background-color: $light-color-1;
  border: 1px solid $grey-color-1;
  color: $black-color-1;
}
