@import '../../../styles/global_variables';
@import '../../../styles/mixins';

.main {
  @include element-size(auto, 100%);
  background-color: $light-color-1;
  border-radius: 12px;
  padding: 16px;
  box-sizing: border-box;
  gap: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .values__container {
    @include element-size(auto, 100%);
    display: flex;

    .value__container {
      @include element-size(auto, 50%);
      display: flex;
      align-items: center;

      .value__text {
        @include labelText;
        font-size: 15px;
      }

      .value__text.pretext {
        opacity: .7;
        margin-right: 4px;
      }

      .value__text.left_space {
        margin-left: 4px;
      }
    }

    .value__container.left_side {
      justify-content: start;
    }

    .value__container.right_side {
      justify-content: end;
    }

  }

  .edge_swiper__container {
    @include element-size(50%, 8px);
    position: absolute;
    bottom: 0;
  }

  .edge_swiper__container.left {
    left: 0;
  }

  .edge_swiper__container.right {
    right: 0;
  }
}
