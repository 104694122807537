@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$image-height: 220px;

.main {
  @include element-size(auto, 100%);
  border: 1px $grey-color-1 solid;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  background: white;
  flex-direction: column;
  padding: 0;
  .title__container {
    @include element-size(auto, 100%);
    display: flex;
    flex-direction: column;
    padding: 16px 20px 20px 20px;
    box-sizing: border-box;

    .project_title {
      font-weight: 590;
      letter-spacing: -0.4px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #09090B;
    }

    .metro_title {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.23px;
      color: $orange-color-1;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      margin-top: 2px;
    }
  }

  .image__container {
    @include element-size($image-height, 100%);
    @include flex-center;

    .img {
      max-height: 90%;
      max-width: 90%;
    }
  }

  .united_info {

    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4px;
    color: #09090B;
    font-size: 17px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }

  .finish_project_info {
    margin-top: 2px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.23px;
    color: $grey-color-4;
  }

  .regular__container {
    @include generalPaddingLeftRight;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 20px;
  }

  .price__container {
    @include generalPadding;
    padding: 8px 20px 28px 20px;

    .price__inner_container {
      display: flex;
      align-items: end;

      .actual_price {
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        letter-spacing: -0.4px;
        color: #09090B;
      }

      .last_price {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.23px;
        color: $grey-color-4;
        text-decoration: line-through;
        margin-left: 8px;
      }
    }
  }

}

.benefits__container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: flex-start;
  flex-direction: row !important;
}
