@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$carousel-height: 440px;
$progress-line-height: 20px;

.main {
  @include element-size(auto, 100%);

  .carousel__container {
    @include element-size($carousel-height, 100%);
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 12px;

    .carousel_item__line {
      @include element-size(100%, 0);
      display: flex;
      position: absolute;
      top: 0;
      overflow: hidden;
      border-radius: 12px;
    }

    .progress_line__container {
      @include element-size($progress-line-height, calc(100% - #{20px * 2}));
      position: absolute;
      bottom: 12px;
      left: 20px;
    }
  }

  :global(.swiper-slide-thumb-active) :global(.benefit-selected-border__container) {
    border: 2px $red-color-1 solid;
  }

  .benefit_selection__container {
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .benefit_selection__slide {
    max-width: 82px;
  }

  .progress_line {
    position: absolute;
    left: 20px;
    bottom: 24px;
    z-index: 1;
    width: calc(100% - #{20px * 2});
    height: 4px;
    border-radius: 4px;
    background-color: rgba($color: #fff, $alpha: .4);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% * var(--progress));
      height: 4px;
      border-radius: 4px;
      background-color: #fff;
    }
  }
}
