@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$gallery-image-height: 300px;
$image-gallery-item-width: 256px;

.main {
  @include element-size(auto, 100%);
  position: relative;

  .scrollable {
    @include element-size(auto, 100%);
    display: flex;
    gap: 10px;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    .item {
      @include element-size(auto, $image-gallery-item-width);
      min-width: $image-gallery-item-width;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .img {
        @include element-size($image-gallery-item-width, $image-gallery-item-width);
        border-radius: 12px;
        object-fit: cover;
        object-position: center;
      }

      .title {
        font-weight: 590;
        line-height: 22px;
        letter-spacing: -0.4px;
      }

      .description {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.23px;
        color: $grey-color-4;
      }
    }
  }

  .loader__container {
    @include element-size(100%, 100%);
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: HexToRGB($grey-color-1, 1);
    pointer-events: none;

    .loader {
      width: 40px;
      height: 40px;
      border: 4px solid $red-color-1;
      border-bottom-color: $pink-color-1;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .scrollable::-webkit-scrollbar {
    display: none;
  }
}