.benefit {
	padding: 6px 12px;
	border: 1px solid  #D4D4D8;
	border-radius: 1000px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: -0.01em;
	text-align: center;
	width: auto;
	color: #71717A;
	&.filled {
		color: #FA1515;
		border-color: #FED0D0;
		background: #FED0D0;
	}
}
