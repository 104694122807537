@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$benefit-height: 30px;

.main {
  @include element-size(auto, 100%);
  display: flex;
  gap: 6px;

  .benefit {
    @include element-size($benefit-height, auto);
    @include flex-center;
    padding-left: calc(#{$benefit-height} / 2);
    padding-right: calc(#{$benefit-height} / 2);
    box-sizing: border-box;
    border-radius: calc(#{$benefit-height} / 2);
    border: 1px solid transparent;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

  }

  .benefit.full_width {
    width: 100%;
  }

  .benefit.red {
    background-color: $pink-color-1;
    border-color: $pink-color-1;
    color: $red-color-1;
  }

  .benefit.white {
    border-color: $grey-color-3;
    color: $grey-color-4;
  }
}