@import "global_variables";

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin element-size($height: 100%, $width: 100%) {
  height: $height;
  width: $width;
}

@mixin generalPadding {
  padding: $general-padding;
  box-sizing: border-box;
}

@mixin generalPaddingLeftRight {
  padding-left: $general-padding;
  padding-right: $general-padding;
  box-sizing: border-box;
}

@mixin labelText {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: $grey-color-5;
}

@function HexToRGB($hex, $opacity) {
  @return rgba(red($hex), green($hex), blue($hex), $opacity);
}
