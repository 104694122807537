@import 'src/styles/global_variables';
@import 'src/styles/mixins';


.apartments__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.main {
  @include element-size(auto, 100%);

  .grey_side__container {
    @include element-size(auto, 100%);
    @include generalPadding;
    background-color: $grey-color-1;
  }

  .white_side__container {
    @include element-size(auto, 100%);
    @include generalPadding;
    background-color: $light-color-1;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .apartments__container {
      @include element-size(auto, 100%);
      transition: $general-transition-time;
      position: relative;

      .not_found__message {
        @include element-size(100px, 100%);
        @include flex-center;
        opacity: .7;
      }
    }

    .apartments_loading__cover {
      @include element-size(100%, 100%);
      position: absolute;
      top: 0;
      left: 0;
      transition: $general-transition-time;
      pointer-events: none;
    }

    .apartments_loading__cover.active {
      background-color: HexToRGB($light-color-1, .8)
    }

    .apartments_loader__container {
      @include element-size(100px, 100px);
      @include flex-center;
      position: fixed;
      bottom: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      transition: $general-transition-time;
    }

    .apartments_loader__container.active {
      opacity: 1;
    }

    .loader {
      width: 40px;
      height: 40px;
      border: 4px solid $red-color-1;
      border-bottom-color: $pink-color-1;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
