@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "src/styles/global_variables";
@import "src/styles/mixins";

@font-face {
  font-family: 'SF Pro';
  src: url('./fonts/SF-Pro.ttf');
  font-display: swap;
}

body {
  font-family: 'SF Pro', sans-serif;
  margin: 0;
  font-size: 17px;
  z-index: 0;
  user-select: none;
}

code {

}

button {
  cursor: pointer;
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  color: $grey-color-5;
  margin-left: 10px;
}

.app_banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #f83e00;
  border-radius: 12px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  &__img {
    width: 328px;
    height: 279px;
    position: absolute;
    right: -95px;
    top: -3px;
  }
  &__video {
    width: 328px;
    height: 279px;
    position: absolute;
    right: -95px;
    top: -3px;
  }
  &__title {
    font-size: 17px;
    font-weight: 590;
    line-height: 22px;
    letter-spacing: -0.4px;
    position: relative;
    z-index: 1;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    margin: 0;
  }
  &__discount {
    font-size: 60px;
    position: relative;
    z-index: 1;
    margin: 0;
    font-weight: 400;
    line-height: 64px;
    letter-spacing: -0.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid $red-color-1;
  border-bottom-color: $pink-color-1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-slide-thumb-active .selected-border__container {
  border: 2px solid $red-color-1;
}
