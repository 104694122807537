@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$main-height: 32px;

.main {
  @include element-size($main-height, 100%);
  border-radius: 9px;
  padding: 2px;
  gap: 2px;
  box-sizing: border-box;
  background-color: $grey-color-1;
  display: flex;
  position: relative;

  .tab__button {
    @include element-size(100%, 100%);
    @include flex-center;
    background-color: transparent;
    border-radius: 7px;
    transition: $general-transition-time;

    .tab__separator {
      @include element-size(calc(#{$main-height} / 2), 100%);
      @include flex-center;
      border: 1px $grey-color-2 none;
      transition: $general-transition-time;
    }

    .tab__separator.visible {
      border-right-style: solid;
    }
  }

  .tab__button.selected {
    background-color: $light-color-1;
    box-shadow: 0 1px 2px HexToRGB($black-color-1, .12);
  }

}