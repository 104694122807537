@import 'src/styles/global_variables';
@import 'src/styles/mixins';

$item-height: 46px;

.main {
  @include element-size(auto, 100%);
  border-radius: 12px;
  background-color: $light-color-1;
  overflow: hidden;

  .item {
    @include element-size($item-height, 100%);
    display: flex;

    .icon__container {
      @include element-size($item-height, $item-height);
      @include flex-center;
    }

    .name_value_general__container {
      @include element-size($item-height, calc(100% - #{$item-height}));
      display: flex;

      .name__container {
        display: flex;
        justify-content: start;
        align-items: center;
        flex: 1;
        padding-left: 10px;
        box-sizing: border-box;
        line-height: 22px;
        color: $grey-color-5;

        font-weight: 400;
      }

      .value__container {
        display: flex;
        justify-content: end;
        align-items: center;
        flex: 1;
        padding-right: 20px;
        box-sizing: border-box;
        line-height: 22px;

        font-weight: 560;
      }
    }

    &:not(:last-child) {
      position: relative;
      // border: 1px rgba(0, 0, 0, 0.15) none;
      // border-bottom-style: solid;
    }

    &:not(:last-child)::before {
      content: '';
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      left: 16px;
      bottom: 0;
    }
  }
}
