@import '../../../styles/global_variables';
@import '../../../styles/mixins';

// this is a general file for drop down list and room count selector components.
// these components have a lot of same logic and styles.

$main-height: 56px;
$main-border-radius: 12px;
$button-height: 36px;
$buttons-gap: 8px;
$option-list-item-height: 48px;

.main {
  @include element-size($main-height, 100%);
  background-color: $light-color-1;
  border-radius: $main-border-radius;
  display: flex;
  position: relative;

  // for room count selector only
  .buttons_area {
    @include element-size(100%, calc(100% - #{$main-height}));
    display: flex;
    align-items: center;
    gap: $buttons-gap;
    padding-left: $buttons-gap;
    padding-right: $buttons-gap;
    box-sizing: border-box;

    .button {
      @include element-size($button-height, auto);
      background-color: $grey-color-1;
      padding: 8px 12px 8px 12px;
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 700;
      border: 1px solid $grey-color-1;
      transition: $general-transition-time;
      font-size: 15px;
    }

    .button.resize_able {
      @include flex-center;
      width: 100%;
    }

    .button.selected {
      background: $red-color-1;
      color: #fff;
    }
  }

  // for drop down list only
  .selected_option_area {
    @include element-size(100%, calc(100% - #{$main-height}));
    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;
  }

  .handler_area {
    @include element-size($main-height, $main-height);
    @include flex-center;
    transition: $general-transition-time;
  }

  .handler_area.open {
    transform: rotate(180deg);
  }

  .option_list__container {
    @include element-size(0, 100%);
    @include generalPaddingLeftRight;
    position: absolute;
    left: 0;
    top: calc(#{$main-height + $buttons-gap});
    background-color: $light-color-1;
    border-radius: $main-border-radius;
    overflow: hidden;
    transition: $general-transition-time;
    z-index: 10;

    .option_list_item {
      @include element-size($option-list-item-height, 100%);
      display: flex;
      align-items: center;
      padding-left: $buttons-gap;
      box-sizing: border-box;
      position: relative;

      &:not(:last-child) {
        border: 1px rgba(0, 0, 0, 0.15) none;
        border-bottom-style: solid;
      }

      .option_list_selected_marker__container {
        @include element-size($option-list-item-height, $option-list-item-height);
        @include flex-center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX($option-list-item-height);
        opacity: 0;
        transition: $general-transition-time;
      }

      .option_list_selected_marker__container.visible {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  .option_list__container.open {
    box-shadow: 0 0 2px 2px $grey-color-1;
  }
}
