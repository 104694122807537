//Colors
$light-color-1: #FFF;
$grey-color-1: #F3F3F7;
$grey-color-2: #d9d9d9;
$grey-color-3: #d4d4d8;
$grey-color-4: #71717A;
$grey-color-5: #707579;
$black-color-1: #000;
$blue-color-1: #007AFF;
$red-color-1: #FA1515;
$pink-color-1: #FED0D0;
$orange-color-1: #FB923C;

//Other
$general-transition-time: 0.3s;
$general-padding: 12px;

